import React from "react";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import { getLanguageCode } from '../../utils/common-methods';

const ZENDESK_KEY = "e3975b8f-6479-4be9-8f04-395360c62a56";

const setting = {
  color: {
    theme: "#00004b"
  },
  prefill: {
    email: {
      value: 'isamu@voltron.com',
      readOnly: true
    }
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help"
    }
  },
  contactForm: {
    fields: [
      { id: "description", prefill: { "*": "" } }
    ]
  }
};


export const ZendeskSetLocale = () => {
  setTimeout(() => {
    ZendeskAPI("webWidget", "setLocale", getLanguageCode(localStorage.getItem('i18nextLngTemp')));  // pt-BR fr de en
  }, 100);
};

ZendeskSetLocale();

export const ZendeskWidget = () => (
  <Zendesk zendeskKey={ZENDESK_KEY} {...setting} />
);
