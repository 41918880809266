/* eslint-disable */

import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { Button } from 'antd';
import { isMobile, isChrome } from 'react-device-detect';
import { Loader } from './components/Loader/Loader';
import { toThemeHoc } from './components/styles/Theme';
import { StyledRoot } from './components/App/App.style';
import { history, publicItems, URL_CONSTANTS } from './utils/history';
import { getCountrySpecificData } from './utils/countrySpecificData';
import { isAccessTokenAvailable, getAccountsOAuthUrl } from './utils/common-methods';
import { fetchOrgsList } from './actions/orgsList';
import { fetchUserProfile } from './actions/user';
import { fetchTermsAndConditions } from './actions/termsAndConditions';
import { InfoCarouselContainer } from './components/InfoCarouselModal/InfoCarouselContainer';
import { ConfirmAccountNotification } from './components/ConfirmAccountNotification/ConfirmAccountNotification';
import { getAccountsTnCUrl } from '../src/utils/common-methods';
import { iff } from './utils/iff';
import { FooterNotification } from './components/common/FooterNotification';

const Private = lazy(() => import('./modules/private'));
const Public = lazy(() => import('./modules/public'));

const StyledContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 100px;
`;

export const StyledButton = styled(Button)`
  background: #aab400;
  align-items: center;
`;

const App = (props) => {
  var showConfirmationModal = false;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(
    isAccessTokenAvailable() && !publicItems.includes(location.pathname)
  );

  useEffect(() => {
    if (isAccessTokenAvailable() && !publicItems.includes(location.pathname)) {
      props.fetchTermsAndConditions();
      props.fetchOrgsList();
      props.fetchUserProfile();
    }
  }, []);

  useEffect(() => {
    if (props.termsSuccess) {
      let unsignedTerms = [];
      if (!isEmpty(get(props, 'terms', []))) {
        unsignedTerms = get(props, 'terms', []).filter((term) =>
          term.plans.find((plan) => plan.signed === false)
        );
      }
      for (let term of unsignedTerms) {
        let checkForUnsignedTerm = term.plans.find((plan) => {
          return plan.signed === false;
        });
        if (checkForUnsignedTerm) {
          window.location.href = getAccountsTnCUrl();
        }
      }
    }
  }, [props.termsSuccess]);

  useEffect(() => {
    const { location } = history;
    if (props.orgsSuccess && props.termsSuccess) {
      if (!location.pathname.includes('/app') && !publicItems.includes(location.pathname)) {
        if (!isEmpty(props.orgs) && props.orgs.length === 1) {
          history.push(URL_CONSTANTS.LANDING_ORGANIZATION({ orgId: props.orgs[0] }));
        } else {
          history.push(URL_CONSTANTS.HALL_OF_ORG());
        }
      }
      setIsLoading(false);
    } else if (!isEmpty(props.orgsError)) {
      setIsLoading(false);
    }
  }, [props.orgsSuccess, props.orgsError, props.termsSuccess]);

  useEffect(() => {
    if (!isEmpty(props.termsError)) {
      if (props.termsError.toString().includes('Request failed with status code 401')) {
        window.location.href = getAccountsOAuthUrl();
      } else {
        setIsLoading(false);
      }
    }
  }, [props.termsError]);

  if (props.orgsError || props.termsError) {
    return (
      <StyledContent>
        <h1 style={{ fontSize: '28px' }}>Something went wrong</h1>
        <span>Hang tight - we&lsquo;ve notified the team.</span>
        <br />
        <StyledButton className='btn-green' size='large' onClick={() => window.location.reload()}>
          {t('Retry')}
        </StyledButton>
      </StyledContent>
    );
  } else {
    return (
      <StyledRoot>
        <Helmet>
          <title>{t(getCountrySpecificData('Cropwise'))}</title>
        </Helmet>
        <Router history={history}>
          <Suspense fallback={<Loader message='Loading...' />}>
            <Switch>
              <Route
                exact
                path='/app/*'
                render={(p) => <Private {...p} loading={props.termsLoading || isLoading} />}
              />
              <Route path='/' component={Public} />
            </Switch>
          </Suspense>
        </Router>
        <InfoCarouselContainer termsSuccess={props.termsSuccess} />
        {iff(showConfirmationModal === true, <ConfirmAccountNotification />)}
        <FooterNotification isChrome={isChrome} isMobile={isMobile} />
      </StyledRoot>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    orgs: get(state, 'orgsList.data.result', []),
    terms: get(state, 'termsAndConditions.data', []),
    termsSuccess: get(state, 'termsAndConditions.success', false),
    termsLoading: get(state, 'termsAndConditions.loading', false),
    termsError: get(state, 'termsAndConditions.error', null),
    orgsLoading: get(state, 'orgsList.loading', []),
    orgsError: get(state, 'orgsList.error', null),
    orgsSuccess: get(state, 'orgsList.isFetchOrgListSuccess', false)
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchOrgsList: () => dispatch(fetchOrgsList()),
  fetchUserProfile: () => dispatch(fetchUserProfile()),
  fetchTermsAndConditions: () => dispatch(fetchTermsAndConditions())
});

export const AppLazy = connect(
  mapStateToProps,
  mapDispatchToProps
)(toThemeHoc(App, 'defaultTheme'));
